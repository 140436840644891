import VueRouter from 'vue-router'

export default new VueRouter({
    routes: [
        {
            path: '/',
            component: () => import(/* webpackChunkName: "index" */ '../pages/index/index.vue')
        },
        {
            path: '/index',
            component: () => import(/* webpackChunkName: "index" */ '../pages/index/index.vue')
        },
        {
            path: '/culture',
            component: () => import(/* webpackChunkName: "culture" */ '../pages/culture/index.vue')
        },
        {
            path: '/company',
            component: () => import(/* webpackChunkName: "company" */ '../pages/company/index.vue')
        },
        {
            path: '/product',
            component: () => import(/* webpackChunkName: "product" */ '../pages/product/index.vue')
        },
        {
            path: '/productdetail',
            component: () => import(/* webpackChunkName: "productdetail" */ '../pages/productdetail/index.vue')
        },
        {
            path: '/factory',
            component: () => import(/* webpackChunkName: "factory" */ '../pages/factory/index.vue')
        },
        {
            path: '/order',
            component: () => import(/* webpackChunkName: "order" */ '../pages/order/index.vue')
        },
        {
            path: '/history',
            component: () => import(/* webpackChunkName: "history" */ '../pages/history/index.vue')
        },
        {
            path: '/contact',
            component: () => import(/* webpackChunkName: "contact" */ '../pages/contact/index.vue')
        }
    ]
})