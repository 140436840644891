module.exports = {
  headerTitle: '青岛黑盾防护科技有限公司',
  headerMenu: {
    item0: '首页',
    item1: '文化理念',
    item2: '公司简介',
    item3: '产品介绍',
    item4: '工厂介绍',
    item5: '订单流程',
    item6: '发展历史',
    item7: '联系我们',
  },
  home: {
    choose: '选择黑盾，让您安心',
    today1: '年产量超',
    today2: '1亿',
    today3: '枚',
    protect: '自有工厂 品质制造',
    three: '黑盾3大',
    adv: '优势',
    hei: '黑盾的',
    design: '设计研发',
    intel: '智能化制造',
    quality: '品质把控',
    item2Text11: '生产线智能制造',
    item2Text12: '——在线视觉检测代替人工，高效、安全`。',
    item2Text21: '传动带式金属检测仪机',
    item2Text22: '——加强对生产过程和原料来源的控制；提高了生产效率同时保障了产品的质量安全。',
    item2Text31: '全自动包装机',
    item2Text32: '——提高工作效率，减少人为接触次数，把控健康卫生环境。',
    item2Text41: '数字工厂管理系统',
    item2Text42: '——实现生产过程一体化管理，安全、高产能，保障订单及时交付。',
    adv3Step1Title: '来料入厂检',
    adv3Step2Title: '口罩片检',
    adv3Step3Title: '产品过程检',
    adv3Step4Title: '成品检',
    about: '关于黑盾的',
    report: '国际报道',
    japReport: '日本 电视报道',
    koreaReport: '韩国 电视报道',
    reportContent1: '使用发明专利RYMOLAY超细纤维无纺布制成的口罩，在日本高知电视台、日本电视台、朝日电视台相继播出，超过三分之一的日本民众收看了黑盾口罩的生产视频。黑盾品牌在日本享有较高声誉。',
    reportContent2: '黑盾品牌口罩在韩国电视台播出宣传，受到了韩国民众的喜爱和好评。同时取得了韩国KF94、KF99认证，以及韩国医疗器械准入许可证。'
  },
  footer: {
    addr: '山东省青岛市城阳区赵哥庄社区（青岛新联工贸有限公司院内六号西厂房）',
    hei: '青岛黑盾防护科技有限公司专业从事医用、民用及工业用口罩的生产、研发与销售。'
  },
  culture: {
    culture: '企业文化',
    manage: '经营理念',
    durty: '使命与责任',
    insist: '坚持员工与企业的共同成长',
    long: '坚持长期主义',
    custom: '与客户、供应商日益加深信任关系',
    profile: '同时满足客户、企业与社区三方的利益',
    value: '向社会提供有价值的产品和服务',
    cultureTitle: '黑盾的文化',
    manageTitle: '黑盾的经营理念',
    durtyTitle: '黑盾使命与责任'
  },
  company: {
    welcome: '欢迎来到青岛黑盾防护科技有限公司',
    from: '自2015年成立以来,一直致力于口罩制造与销售。',
    our: '我们专业从事医用、民用及工业用口罩的研发、生产与销售。',
    company: '公司已获得ISO 13485质量认证体系及国内外多项产品认证,为参与国际竞争奠定基础。',
    future: '未来的黑盾产品将会在国际国内市场稳步提升销量,依靠优质的产品品质赢得消费者的品牌。',
    info: '信息',
    introduce: '公司简介',
    tele: '电话',
    email: '邮箱',
    addr: '营运地址',
    addr1: '山东省青岛市城阳区赵哥庄社区',
    addr2: '( 青岛新联工贸有限公司院内六号西厂房 )',
    research: '黑盾的研发',
    make: '黑盾的制造',
    upgrade: '生产全线升级   智能化生产',
    countrys: '多国检测认证及报告',
    pass: '通过国内外专业验厂',
    hang: '香港屈臣氏验厂(UL)',
    america: '美国WBA沃博联验厂(TUV)',
    family: 'FAMILY MART的SGS验厂',
  },
  product: {
    brand: '品牌介绍',
    introduce: '黑盾于 2015 年投产运营，厂区建筑面积 8000 多平方米，配备十万级医疗器械生产洁净化车间 2 个，拥有全自动生产线 33 条，配备全自动视觉检测机，全自动口罩包装机，日产能 100万 只。',
    ident: '公司取得多项海内外认证：',
    itemList: [
      "获得ISO 13485认证",
      "获得日本国标JIS T9001医用口罩level3认证",
      "获得日本KAKEN实验室BFE/PFE/VFE99的认证",
      "获得韩国KFDA的KF99/94/80认证",
      "通过韩国医疗口罩准入许可",
      "获得欧盟TYPE I、TYPE II 和 TYPE IIR 的 CE认证",
      "通过美国NELSON实验室 ASTM F2100-2019标准的LEVEL3检测",
    ],
    now: '现有产品',
    plane: '平面口罩',
    tShape: 'T型口罩',
    boat: '船型口罩',
    threed: '3D立体口罩',
    li: '立体口罩',
    n95: 'KN95口罩',
    glass: '护目镜口罩',
    one: '一次性防护服',
    cloth: '一次性隔离衣',
    cop: '合作品牌',
    want: '期待与您合作！',
  },
  factory: {
    step: '生产步骤',
    stepInfo: [
      {
        index: 1,
        title: '脱外包',
        text: '脱去原辅材料外包装'
      },
      {
        index: 2,
        title: '物料暂存消毒',
        text: '物料经缓冲间运送到物料暂存间，在此对物料进行消毒'
      },
      {
        index: 3,
        title: '上 料',
        text: '将原材料装上料架'
      },
      {
        index: 4,
        title: '生 产',
        text: '拥有全自动生产线 33 条，配备全自动视觉检测机, 日产能达到100万只口罩, 生产过程中进行巡检、抽检'
      },
      {
        index: 5,
        title: '片 检',
        text: '每片口罩都进行检查，安全生产，保证产品质量'
      },
      {
        index: 6,
        title: '内 包',
        text: '在医疗器械生产洁净化车间内进行内包'
      },
      {
        index: 7,
        title: '外 包',
        text: '将内包装好的产品有序装箱'
      },
      {
        index: 8,
        title: '成品检测',
        text: '对成品抽样，根据相应标准进行成品检测'
      },
    ]
  },
  order: {
    step1: '明确客户需求',
    step2: '提供满足客户需求的解决方案',
    step3: '确定配比、性能、包装方案和最小起订量',
    step4: '确定成交价格，确认交付时间',
    step5: '成交接单',
    step6: '完成生产及最后成品入库抽检',
    step7: '如期交付',
  },
  history: {
    y14title: '2014年',
    y15title: '2015年',
    y19title: '2019年',
    y20title: '2020年',
    y21title: '2021年4月',
    y22title: '2022年5月',
    y14: '筹建公司',
    y15: '建立黑盾口罩工厂',
    y19: '成立日本大阪办事处',
    y20: '新车间启用',
    y21: '黑盾持续升级，与【韩国looka】口罩品牌展开合作，联合研发透气时尚新型口罩',
    y22: '2gramask 实现设备研发，包装机械研发，产品进入大生产',
  },
  contack: {
    us: '联系我们',
    pleasure: '乐意效劳',
    help: '我们的客户服务团队随时为您提供帮助，回答您的任何问题',
    name: '您的姓名',
    namep: '请输入您的真实姓名',
    tele: '您的联系电话',
    telep: '请输入您的联系电话',
    content: '您的联系内容',
    contentp: '请输入您的具体联系内容',
    tips: '请按住滑块，拖动到最右边',
    submit: '确认提交',
    addr: '营运地址',
    addr1: '山东省青岛市城阳区赵哥庄社区',
    addr2: '( 青岛新联工贸有限公司院内六号西厂房 )',
  }
}