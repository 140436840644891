<template>
  <div class="header-container" :style="`width: ${windowWidth}px`">
    <img @click="toHome" src="@/assets/home/header-logo.png" class="header-icon"/>
    <!-- <img @click="toHome" v-if="$i18n.locale==='zh-CN'" src="@/assets/home/header-logo.png" class="header-icon"/>
    <img @click="toHome" v-if="$i18n.locale==='en-US'" src="@/assets/home/header-logo-en.png" class="header-icon"/> -->
    <div @click="toHome" class="icon-text">
      <div class="text1">{{$t('headerTitle')}}</div>
      <div class="text2">Qingdao Hidoon Protection Technology Co., Ltd.</div>
    </div>
    <el-tabs @tab-click="handleClick" :value="curPath" v-if="menuTabShow">
      <el-tab-pane v-for="item in menuData" :key="item.path" :label="item.name" :name="item.path"></el-tab-pane>
    </el-tabs>
    <el-dropdown @command="langChange">
      <span class="el-dropdown-link">
        <img src="@/assets/home/i18.png" class="i18-icon"/>{{language==='zh-CN'? '中文' : (language==='en-US'? 'English' : '日本語')}}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="zh-CN">中文</el-dropdown-item>
        <el-dropdown-item command="en-US">English</el-dropdown-item>
        <el-dropdown-item command="jap-JPN">日本語</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: 'index-header',
  data() {
    return {
      language: 'zh-CN',
      curPath: 'index',
      windowWidth: 1920,
      menuTabShow: true,
      menuData: [
        {
          path: 'index',
          name: '首页'
        },
        {
          path: 'culture',
          name: '文化理念'
        },
        {
          path: 'company',
          name: '公司简介'
        },
        {
          path: 'product',
          name: '产品介绍'
        },
        {
          path: 'factory',
          name: '工厂介绍'
        },
        {
          path: 'order',
          name: '订单流程'
        },
        {
          path: 'history',
          name: '发展历史'
        },
        {
          path: 'contact',
          name: '联系我们'
        },
      ]
    }
  },
  mounted() {
    window._q = this
    this.$bus.$on('size', (height, width) => {
      this.windowWidth = width
    })
    setInterval(() => {
      this.curPath = this.$route.path.split('/')[1] || 'index'
    }, 200)
  },
  methods: {
    handleClick(ins) {
      this.$router.push('/'+ins.name)
      window.scrollTo(0,0)

      setTimeout(() => {
        let windowWidth = document.body.clientWidth < 1200? 1200 : document.body.clientWidth
        this.windowWidth = windowWidth - 2
        let windowHeight = window.innerHeight < 650? 650 : window.innerHeight
        this.windowHeight = windowHeight
        this.$bus.$emit('size', this.windowHeight, this.windowWidth)
      }, 30)
    },
    toHome() {
      this.$router.push('/index')
      window.scrollTo(0,0)
    },
    langChange(language) {
      this.language = language
      this.$i18n.locale = this.language
      this.menuData.forEach((item, idx) => {
        item.name = this.$t('headerMenu.item' + idx)
      })
      this.menuTabShow = false
      setTimeout(() => {
        this.menuTabShow = true
      }, 10)
      this.$bus.$emit('updateLanguage', language)
    },
  }
}
</script>

<style lang="less" scoped>
  .header-container {
    background-color: white;
    width: 100%;
    height: 100px;
    top: 0;
    position: fixed;
    z-index: 100;
    border-bottom: 1px solid #89bfd9;

    .header-icon {
      width: 130px;
      display: inline-block;
      margin-left: 37px;
      margin-top: 15px;
      cursor: pointer;
    }

    .icon-text {
      display: inline-block;
      margin-left: 19px;
      cursor: pointer;

      .text1 {
        font-size: 24px;
        color: #666666;
        letter-spacing: 4.8px;
      }
      .text2 {
        font-size: 14px;
        color: #999999;
        line-height: 30px;
        letter-spacing: 1px;
      }
    }

    .i18-icon {
      vertical-align: middle;
      height: 14px;
      width: 14px;
    }

    .el-tabs {
      display: inline-block;
      position: absolute;
      right: 100px;
      line-height: 80px;

      /deep/.el-tabs__header {
        margin: 0;

        .el-tabs__item {
          padding: 0 1.3vw;
          font-size: 17px;
          color: #666666;
        }

        .el-tabs__nav-wrap::after {
          background-color: white;
        }

        .el-tabs__active-bar {
          height: 7px;
          background-color: #89BFD9;
        }

        .el-tabs__item:hover {
          color: #89BFD9;
        }

        .el-tabs__item.is-active {
          color: #89BFD9;
        }
      }
    }

    .el-dropdown {
      position: absolute;
      right: 20px;
      top: 30px;
    }
  }
    
  .el-dropdown-menu__item {
    font-size: 17px;
  }

  @media screen and (max-width: 1500px) {
    .header-container {
      height: 70px;

      .header-icon {
        width: 88px;
      }
      .icon-text {
        .text1 {
          font-size: 18px;
          letter-spacing: 3.4px;
        }
        .text2 {
          font-size: 12px;
          letter-spacing: 0;
          line-height: 16px;
        }
      }

      .el-tabs {
        line-height: 60px;
        right: 100px;
        
        /deep/.el-tabs__header {
          .el-tabs__item {
            padding: 0 1vw;
            font-size: 14px;
            color: #666666;
          }

          .el-tabs__active-bar {
            height: 4px;
            bottom: 5px;
          }
        }
      }

      .el-dropdown {
        top: 20px;
        font-size: 14px;
        right: 15px;
      }
    }
    
    /deep/.el-dropdown-menu__item {
      font-size: 14px;
    }
  }

  
  @media screen and (min-width: 2000px) {
    .header-container {
      height: 140px;

      .header-icon {
        margin-left: 52px;
        margin-top: 30px;
        width: 160px;
      }
      .icon-text {
        .text1 {
          font-size: 36px;
          letter-spacing: 6.8px;
        }
        .text2 {
          font-size: 16px;
          letter-spacing: 3.8px;
          line-height: 20px;
        }
      }

      .i18-icon {
        height: 24px;
        width: 24px;
      }

      .el-tabs {
        line-height: 120px;
        right: 160px;
        
        /deep/.el-tabs__header {
          .el-tabs__item {
            padding: 0 1vw;
            font-size: 24px;
            color: #666666;
          }

          .el-tabs__active-bar {
            height: 8px;
            bottom: 10px;
          }
        }
      }

      .el-dropdown {
        top: 40px;
        font-size: 24px;
        right: 30px;
      }
    }
    
    /deep/.el-dropdown-menu__item {
      font-size: 24px;
    }
  }
</style>