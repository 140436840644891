import VueI18n from 'vue-i18n'
import Vue from 'vue'
Vue.use(VueI18n)

export default new VueI18n({
  locale: 'zh-CN',
  messages: {
    'zh-CN': require('./zh.js'),
    'en-US': require('./en.js'),
    'jap-JPN': require('./jap.js')
  }
})