<template>
  <div id="app" :style="`width: ${windowWidth}px`">
    <header-index></header-index>
    <router-view></router-view>
    <footer-index></footer-index>
  </div>
</template>

<script>
import headerIndex from '@/components/header.vue'
import footerIndex from '@/components/footer.vue'

export default {
  name: 'App',
  mounted() {
    setTimeout(() => {
      this.getSize()
    }, 50)

    setInterval(() => {
      this.getSize()
    }, 1000)

    window.onresize = () => {
      this.getSize()
    }
    window.onscroll = this.throttle(this.scroll, 10)
    window._this = this
  },
  data() {
    return {
      windowWidth: 1920,
      windowHeight: 1080,
      lastTime: null
    }
  },
  methods: {
    getSize() {
      let windowWidth = document.body.clientWidth < 1200? 1200 : document.body.clientWidth
      this.windowWidth = windowWidth - 2

      let windowHeight = window.innerHeight < 650? 650 : window.innerHeight
      this.windowHeight = windowHeight
      this.$bus.$emit('size', this.windowHeight, this.windowWidth)
    },
    scroll() {
      this.$bus.$emit('scroll')
    },
    // 节流函数-时间戳方案
    throttle(fn, gapTime) {
        if (gapTime == null || gapTime == undefined) {
            return
        }
        const _this = this
        this.lastTime = null
        // 返回新的函数
        return function () {
            let _nowTime = +new Date()
            if (_nowTime - _this.lastTime > gapTime || !_this.lastTime) {
                fn.apply(this, arguments) //将this和参数传给原函数
                _this.lastTime = _nowTime
            }
        }
    },
  },
  components: {
    headerIndex,
    footerIndex
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* 禁止文本的选中 */
  -webkit-user-select:none;
  -moz-user-select:none;
  -o-user-select:none;
  user-select:none;
}
body {
  margin: 0;
}
</style>
