module.exports = {
  headerTitle: '青岛黑盾防护科技有限公司',
  headerMenu: {
    item0: 'Home',
    item1: 'Culture',
    item2: 'About us',
    item3: 'Products',
    item4: 'Factory',
    item5: 'Order',
    item6: 'History',
    item7: 'Contact',
  },
  home: {
    choose: 'CHOOSE HIDOON, CHOOSE SAFE AND SECURE',
    today1: 'Annual output exceeds',
    today2: '100 million',
    today3: 'pieces',
    protect: 'Self-owned factory   Quality gurantee',
    three: 'Hidoon 3',
    adv: 'advantages',
    hei: 'Hidoon ',
    design: 'Design and R&D',
    intel: 'Intelligent Manufacture',
    quality: 'Quality Control',
    item2Text11: 'Intelligent production line',
    item2Text12: '——Online visual inspection instead of manual, efficient and safe.',
    item2Text21: 'Conveyor belt metal detector machine',
    item2Text22: '——Strengthens the control of production process and source of raw materials; Improves production efficiency and meanwhile guarantees product quality.',
    item2Text31: 'Automatic Packaging machine',
    item2Text32: '——Improve work efficiency, reduce the number of human contact, control the healthy environment.',
    item2Text41: 'Digital factory management system',
    item2Text42: '——Achieved integrated management of production process, high safety, high productivity, ensured timely delivery of orders.',
    adv3Step1Title: 'Incoming materials inspection',
    adv3Step2Title: 'Masks inspection',
    adv3Step3Title: 'Products Process Inspection',
    adv3Step4Title: 'Finished Products Inspection',
    about: 'International Report of ',
    report: 'Hidoon',
    japReport: 'Japanese TV Report',
    koreaReport: 'Korean TV Report',
    reportContent1: 'Masks made of RYMOLAY superfine fiber non-woven fabric broadcast on Japan Kochi TV, Japanese TV, and Japan Asahi TV, over one-third of Japanese people watched the video of Hidoon masks production. Hidoon brand enjoys high reputation in Japan.',
    reportContent2: 'Hidoon brand masks have been advertised on South Korean TV and are highly welcome by the Korean people.  At the same time, Hidoon obtained Korea KF94, KF99 certification, and Korea Medical Device Access License. ',
  },
  footer: {
    addr: 'Zhaogezhuang Community, Chengyang district, Qingdao, Shandong (No. 6 workshop Xinlian Industrial park)',
    hei: 'Qingdao Hidoon Protection Technology Co., LTD mainly specializes in production, Research & Development and sales of medical masks, civil masks and industrial masks.'
  },
  culture: {
    culture: 'Culture',
    manage: 'Operation Principle',
    durty: 'Mission and Responsibility',
    insist: 'Adhere to employees and enterprise growing together',
    long: 'Adhere to long-term development',
    custom: 'Deepening trust with customers and suppliers increasingly',
    profile: 'To meet the interests of customers, enterprises and communities at the same time',
    value: 'Provide valuable products and services to society',
    cultureTitle: 'Hidoon culture',
    manageTitle: 'Hidoon Operation Principle',
    durtyTitle: 'Hidoon Mission and Responsibility'
  },
  company: {
    welcome: 'Welcome to Hidoon Protection Technology Corporation',
    from: 'Since founded in 2015, Hidoon is always committed to the manufacture and sales of masks.',
    our: 'We mainly specialize in the development, production, and sales of medical masks, civil masks and industrial masks.',
    company: 'We have ISO13485 quality certification system and also obtained many product certifications home and abroad, lay the foundation for winning international competition.',
    future: 'In future, Hidoon will steadily increase sales in the international and domestic markets, win high reputation with excellent quality.',
    info: 'Information',
    introduce: 'Company profile',
    tele: 'Tel',
    email: 'Email',
    addr: 'Add',
    addr1: 'Zhaogezhuang Community, Chengyang district, Qingdao, Shandong ',
    addr2: '( No. 6 workshop Xinlian Industrial park )',
    research: 'Hidoon Research & Development',
    make: 'Hidoon Manufacture',
    upgrade: 'All production lines upgraded to intelligent production',
    countrys: 'Domestic and Overseas testing certifications and reports',
    pass: 'Pass professional factory inspection home and abroad',
    hang: 'Hongkong Watsons Inspection (UL)',
    america: 'America WBA Inspection(TUV)',
    family: 'SGS factory inspection for FAMILY MART ',
  },
  product: {
    brand: 'Brand Introduction',
    introduce: 'Qingdao Hidoon Protection Technology Co., LTD founded in 2015, has a construction area of more than 8,000 square meters, and equipped with two one hundred thousand grade purification workshops. It has 33 automatic production lines, equipped with automatic vision inspection machines and automatic mask packaging machines, the daily production capacity is more than 1 million pieces. ',
    ident: 'Hidoon obtained a number of domestic and overseas certifications:',
    itemList: [
      "ISO13485 Medical Device Quality Management System Certification",
      "Japan JIS T 9001 Medical Masks Level 3 certificate",
      "Japan KAKEN Laboratory BFE/PFE/VFE99 Certificate",
      "Korea KFDA KF99/94/80 Certificate",
      "Korea Medical Device Access License",
      "CE certification for disposable medical masks (TYPE I/II/IIR)",
      "Pass ASTM F2100-2019 LEVEL 3 Test by NELSON laboratory",
    ],
    now: 'Current Products',
    plane: 'Plane Masks',
    tShape: 'T Shape Masks',
    boat: 'Boat Shape Masks',
    threed: '3D Masks',
    li: '3D face mask',
    n95: 'KN95 face mask',
    glass: 'Goggles face mask',
    one: 'Disposable protective gowns',
    cloth: 'Disposable isolation suit',
    cop: 'Cooperated Brands',
    want: 'Look forward to cooperating with you!',
  },
  factory: {
    step: 'Production Steps',
    stepInfo: [
      {
        index: 1,
        title: 'Take off the outsourcing',
        text: 'Remove the outer packing of raw materials and auxiliary materials'
      },
      {
        index: 2,
        title: 'Materials temporary stored and disinfected',
        text: 'The materials are transported through the buffer room to the temporary storage room, and get them disinfected here'
      },
      {
        index: 3,
        title: 'Load Materials',
        text: 'Load the raw materials onto the rack'
      },
      {
        index: 4,
        title: 'Production',
        text: '33 automatic production lines, equipped with automatic vision inspection machine, daily production can reach to 1 million pieces. In-process inspection and sampling inspection will be done during production'
      },
      {
        index: 5,
        title: 'Mask Inspection',
        text: 'Check quality of each mask to ensure safe production and high quality'
      },
      {
        index: 6,
        title: 'Inner packing',
        text: 'Inner packing will be done in the medical device purification workshop'
      },
      {
        index: 7,
        title: 'Outer packing',
        text: 'Put the inner packed products into outer cartons orderly'
      },
      {
        index: 8,
        title: 'Finished-products Inspection',
        text: 'Take samples from finished-products, and get them tested according to corresponding standards'
      },
    ]
  },
  order: {
    step1: 'Determining customer’s needs',
    step2: 'Provide solutions that can meet customer’s needs',
    step3: 'Determine ratio, performance, packaging method and MOQ',
    step4: 'Confirm price and delivery time',
    step5: 'Order confirmed and signed',
    step6: 'Complete production, implement finished-products inspection and put in storage',
    step7: 'To deliver on time',
  },
  history: {
    y14title: '2014',
    y15title: '2015',
    y19title: '2019',
    y20title: '2020',
    y21title: 'April, 2021',
    y22title: 'May, 2022',
    y14: 'Planned to build up the company',
    y15: 'Built Hidoon masks factory',
    y19: 'Set up Osaka Office in Japan',
    y20: 'New workshop put into service',
    y21: 'Hidoon kept on upgrading, cooperated with [Korean LOOKA] mask brand to jointly developed breathable and fashionable new masks',
    y22: '2gramask realized equipment developed, packing machine developed, put into mass production',
  },
  contack: {
    us: 'Contact us',
    pleasure: 'At your service',
    help: 'Our customer service team is here to help you and answer any questions you may have',
    name: 'Your name',
    namep: 'Please input your real name',
    tele: 'Tel',
    telep: 'Please input your phone number',
    content: 'Content',
    contentp: 'Please input the contact contents',
    tips: 'Please hold down the slider and drag to the end right',
    submit: 'Submit',
    addr: 'Business address',
    addr1: 'Zhaogezhuang Community, Chengyang district, Qingdao, Shandong',
    addr2: '( No. 6 workshop Xinlian Industrial park )',
  }
}