<template>
  <div>
    <div class="footer-1">
      <div class="footer-icon"></div>
      <div class="icon-text">
        <div class="text1">青岛黑盾防护科技有限公司</div>
        <div class="text2">Qingdao Hidoon Protection Technology Co., Ltd.</div>
      </div>
      <div class="footer-line2">
        <div class="router-button">
          <el-button type="text" @click="handleClick(`index`)">{{$t('headerMenu.item0')}}</el-button>
          <el-button type="text" @click="handleClick(`culture`)">{{$t('headerMenu.item1')}}</el-button>
          <el-button type="text" @click="handleClick(`company`)">{{$t('headerMenu.item2')}}</el-button>
          <el-button type="text" @click="handleClick(`product`)">{{$t('headerMenu.item3')}}</el-button>
        </div>
        <div class="router-button">
          <el-button type="text" @click="handleClick(`factory`)">{{$t('headerMenu.item4')}}</el-button>
          <el-button type="text" @click="handleClick(`order`)">{{$t('headerMenu.item5')}}</el-button>
          <el-button type="text" @click="handleClick(`history`)">{{$t('headerMenu.item6')}}</el-button>
          <el-button type="text" @click="handleClick(`contact`)">{{$t('headerMenu.item7')}}</el-button>
        </div>
        <div class="contact">
          + 86-0532-80772559 / + 86-0532-80772511
          <br/>
          service@ hidoon.com
          <br/>
          {{$t('footer.addr')}}
        </div>
      </div>
    </div>
    <div class="footer-2">
      <div>
        {{$t('footer.hei')}}
        <br/>
        Copyright © 2015 青岛黑盾防护科技有限公司 All Rights Reserved.
        <br/>
        <span @click="toBeiAn1" class="beian">鲁ICP备15040844号-1</span>
        <span @click="toBeiAn2" class="beian" style="margin-left: 3%">公安备案号 37021402000266</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index-header',
  methods: {
    handleClick(path) {
      this.$router.push('/' + path)
      window.scrollTo(0,0)
    },
    toBeiAn1() {
      window.open("https://beian.miit.gov.cn/", "_blank")
    },
    toBeiAn2() {
      window.open("https://www.beian.gov.cn/portal/recordQuery?token=5de9cd42-a908-4d10-acf0-7f9f44fdd2a4", "_blank")
    },
  }
}
</script>

<style lang="less" scoped>
.footer-1 {
  height: 506px;

  .footer-icon {
    background-image: url('@/assets/home/header-logo.png');
    background-size: 100%;
    height: 58px;
    width: 130px;
    display: inline-block;
    margin-left: 11%;
    margin-top: 115px;
  }

  .icon-text {
    display: inline-block;
    margin-left: 19px;

    .text1 {
      font-size: 24px;
      color: #666666;
      letter-spacing: 4.8px;
    }
    .text2 {
      font-size: 14px;
      color: #999999;
      line-height: 30px;
      letter-spacing: 1px;
    }
    @media screen and (max-width: 1500px) {
      .text1 {
        letter-spacing: 1px;
      }
      .text2 {
        letter-spacing: 0;
      }
    }
  }

  .footer-line2 {
    position: relative;
    margin-left: 13%;
    margin-top: 60px;
    
    .router-button {
      display: inline-block;
      width: 10%;
      position: relative;

      .el-button {
        display: block;
        color: black;
        margin-left: 0;
        margin-top: 2px;
      }

      .el-button:hover {
        color: #4C9AC0;
      }
    }

    .router-button::before {
      content: "";
      width: 1px;
      height: 172px;
      background: #ABABAB;
      position: absolute;
      left: -25%;
    }

    .contact {
      position: absolute;
      right: 10%;
      top: 10%;
      font-size: 17px;
      color: #333333;
      line-height: 44px;
      margin-left: 30%;
    }
  }
}

.footer-2 {
  height: 156px;
  background: #525252;
  text-align: center;
  font-size: 15px;
  color: #FFFFFF;
  line-height: 25px;
  padding-top: 33px;
  letter-spacing: 2px;
  

  .beian:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

@media screen and (min-width: 2000px) {
  .footer-1 {
    height: 706px;

    .footer-icon {
      width: 180px;
      height: 80px;
    }

    .icon-text {
      margin-left: 30px;

      .text1 {
        font-size: 36px;
      };

      .text2 {
        font-size: 21px;
        line-height: 50px;
        letter-spacing: 0.9px;
      }
    }

    .footer-line2 {
      margin-top: 90px;

      .router-button {
        .el-button {
          font-size: 21px;
        }
      }

      .router-button::before {
        height: 207px;
      }

      .contact {
        font-size: 24px;
        line-height: 54px;
      }
    }
  }

  .footer-2 {
    height: 206px;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 33px;
    padding-top: 80px;
    letter-spacing: 2px;
  }
}
</style>