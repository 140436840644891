
import '@/assets/font/font.css'
import Vue from 'vue'
import { 
  Input,
  Button,
  Select,
  Option,
  TabPane,
  Tabs,
  Carousel,
  CarouselItem,
  Message,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from 'element-ui';
import App from './App.vue'
import Router from 'vue-router'
import router from './route/index'
import 'animate.css'
import i18n from './i18n/index.js'

Vue.config.productionTip = false

Vue.use(Input)
Vue.use(Button)
Vue.use(Select)
Vue.use(Option)
Vue.use(TabPane)
Vue.use(Tabs)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.prototype.$message = Message

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'pEt8ifjsiUVEhG0vGeOPwWl8LmkWfDAK'
})

Vue.prototype.$bus = new Vue()

Vue.use(Router)

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
