module.exports = {
  headerTitle: '青島黒盾防護科技有限公司',
  headerMenu: {
    item0: 'トップページ',
    item1: '企業理念',
    item2: '会社概要',
    item3: '製品情報',
    item4: '工場情報',
    item5: '発注プロセス',
    item6: '発展歴史',
    item7: 'お問合せ',
  },
  home: {
    choose: '黒盾を選択し、安心させよう。',
    today1: '年間生産キャパは',
    today2: '1億',
    today3: '枚を超えます',
    protect: '自社工場で、品質保証できる',
    three: '黒盾三つの',
    adv: '優勢',
    hei: '黒盾の',
    design: '研究開発',
    intel: '知的製造',
    quality: '品質コントロール',
    item2Text11: '生産ライン知的製造',
    item2Text12: '--全自動カメラ付き監視機器は人に代わり、高効率化、安全。',
    item2Text21: 'コンベアーベルト金属探知機',
    item2Text22: '--生産工程と原材料由来のコントロールを強化し、生産効率を高めると同時に商品の品質安全を保障する。',
    item2Text31: '全自動包装機',
    item2Text32: '--生産効率を高め、人的接触を減少し、健康、衛生な環境をコントロール。',
    item2Text41: 'デジタル管理システム',
    item2Text42: '--生産工程の一体化管理を実現し、安全、高産能で、タイムリー出荷することを保障。',
    adv3Step1Title: '原材料受入検査',
    adv3Step2Title: 'マスク確認',
    adv3Step3Title: '製品製造工程確認',
    adv3Step4Title: '製品検品',
    about: '黒盾に対する',
    report: '国際放送',
    japReport: '日本 テレビ局放送',
    koreaReport: '韓国 テレビ局放送',
    reportContent1: '特許が取得済みのRYMOLAY超極細繊維不織布を使用したマスクは日本の高知テレビ局、日本テレビ局、朝日テレビ局で放送され、１／３超えの民衆は黒盾マスクの製造VTRを見た。黒盾ブランドは日本で高く評価されている。',
    reportContent2: '黒盾ブランドのマスクは韓国テレビ局で放送され、民衆の愛用と評価を受けている。同時に韓国のKF94、KF99認証及び韓国医療機器進入許可をもらっている。',
  },
  footer: {
    addr: '青島市城陽区趙哥庄社区（青島新聯工貿有限会社六号西厂房）',
    hei: '青島黒盾防護科技有限公司は医療用マスク、家庭用マスク及び産業用マスクの生産、開発及び販売に専念するマスクメーカーである。'
  },
  culture: {
    culture: '企業文化',
    manage: '経営理念',
    durty: '使命と責任',
    insist: '社員と会社の共同成長を守る',
    long: '長期主義を守る',
    custom: '客先、仕入れ先との信頼関係を益々強化',
    profile: '客先、企業とコミュニティ三方の利益を守る',
    value: '社会に価値あるものとサービスを提供',
    cultureTitle: '黒盾の文化理念',
    manageTitle: '黒盾の経営理念',
    durtyTitle: '黒盾の使命と責任'
  },
  company: {
    welcome: 'ようこそ黒盾防護科技有限会社',
    from: '青島黒盾防護科技有限会社は2015年に設立されて、',
    our: 'ずっと家庭用マスク、医療用マスク、産業用マスクの生産、開発及び販売に専念する。',
    company: '会社は既にISO13485医療器械体系品質認証及び国内外の多くの商品認証を獲得し、国際競争に参加する基礎を築いた。',
    future: '将来的には、黒盾の製品は国際国内市場での販売を着実に高めし、良質な製品品質で消費者を獲得するブランドになる。',
    info: '情報',
    introduce: '会社概要',
    tele: '連絡先',
    email: 'EMAIL',
    addr: '住所',
    addr1: '青島市城陽区趙哥庄社区',
    addr2: '( 青島新聯工貿有限会社六号西厂房 )',
    research: '黒盾の開発研究',
    make: '黒盾の製造',
    upgrade: '生産ラインの全線アップグレード　　インテリジェット生産',
    countrys: '多国検査認証及び報告書',
    pass: '国内外生産現場の監査',
    hang: '香港watsons監査(UL)',
    america: 'アメリカWBA監査(TUV)',
    family: '日本FAMILY MART のSGS合格仕入先監査',
  },
  product: {
    brand: 'ホーム   商品のご案内',
    introduce: '青島黒盾防護科技有限公司は2015年に設立されて、建築面積は8000平方メートル以上である。10万級の医療器械製造用クリーンルームを２つ持ち、全自動生産ライン33本、全自動視覚検査ライン、全自動包装ラインも配備され、1日の生産キャパは100万枚に達している。',
    ident: '国内外の公的認証をいろいろ取得した：',
    itemList: [
      "ISO13485医療器械体系品質認証",
      "日本JIS T 9001 医療用マスクレベル3認証",
      "日本KAKENから出した検査報告書-BFE99%, VFE99%, PFE99%，花粉99%",
      "韓国KFDAのKF80/94/99認証",
      "韓国医療器械進入許可書",
      "EU TYPE I、TYPE II、TYPE IIRのCE認証",
      "米国のASTM F2100-2019レベル3の認証",
    ],
    now: '製品',
    plane: 'プリーツ',
    tShape: 'T型',
    boat: '船型',
    threed: '3D立体型',
    li: '立体マスク',
    n95: 'KN95マスク',
    glass: 'シールド付けフェイスマスク',
    one: '使い捨て防護服',
    cloth: '使い捨てアイソレーションガウン',
    cop: '提携ブランド',
    want: 'ご協力をお待ちしております!',
  },
  factory: {
    step: '生産工程',
    stepInfo: [
      {
        index: 1,
        title: '包装袋を抜く',
        text: '原材料や副資材などの包装袋を抜く'
      },
      {
        index: 2,
        title: '紫外線殺菌',
        text: '原材料が清潔区域で消毒操作をする'
      },
      {
        index: 3,
        title: '原材料投入',
        text: '適切に原材料を投入すること'
      },
      {
        index: 4,
        title: '加 工',
        text: '全自動生産ライン33本、全自動視覚検査ラインを配備され、1日の生産キャパは100万枚に達している。生産中で抜き取り検査と巡検を行う'
      },
      {
        index: 5,
        title: '全数検査',
        text: '全量検品で安全と品質を保証する'
      },
      {
        index: 6,
        title: '内包装',
        text: '医療器械製造用クリーンルームで内包装を行う'
      },
      {
        index: 7,
        title: '外包装',
        text: '包装された製品を順序よく箱詰めする'
      },
      {
        index: 8,
        title: '製品検測',
        text: '製品を抜き取って、相応する標準によって検測することです'
      },
    ]
  },
  order: {
    step1: '客先のニーズを明確にする',
    step2: '客先のニーズを満たすソリューションを提供する',
    step3: '素材、性能、梱包スキームとMOQを明確する',
    step4: '取引単価と出荷納期を決定する',
    step5: 'オーダーになって契約書を作成する',
    step6: '生産完了と最終製品の入庫抜き取り検査を完成する',
    step7: '約束の納期を守って出荷する',
  },
  history: {
    y14title: '2014年',
    y15title: '2015年',
    y19title: '2019年',
    y20title: '2020年',
    y21title: '2021年4月',
    y22title: '2022年5月',
    y14: '会社成立の準備',
    y15: '黒盾のマスク生産工場を設立',
    y19: '日本大阪で事務所を開設',
    y20: '新しい作業場で生産スタート',
    y21: '黒盾は継続的にアップグレードされて、【韓国looka】マスクブランドと協力し、通気性あるファションな新型マスクを共同開発している',
    y22: '2gramaskのマスクは設備の研究開発と包装機械の研究開発を成功になって、製品のバルク生産に入りました',
  },
  contack: {
    us: 'お問い合わせ',
    pleasure: '喜んでご協力させて頂きます',
    help: '私たちのカスタマーサービスチームはいつでも貴方のために、ご協力をお提供',
    name: 'お名前',
    namep: '本名を入力してください',
    tele: 'お電話番号',
    telep: '連絡先の電話番号を入力してください',
    content: 'お問い合わせ内容',
    contentp: 'お問い合わせ内容を記入してください',
    tips: 'スライダを押したまま右端までドラッグしてください',
    submit: '提出のご確認',
    addr: '営業アドレース',
    addr1: '青島市城陽区趙哥庄社区',
    addr2: '( 青島新聯工貿有限会社六号西厂房 )',
  }
}